import { useState, useEffect } from "react";
import "../App.css";
import axios from "axios";
import Cookies from "js-cookie";

import Authtitle from "../components/AuthTitle";
import AuthInputs from "../components/AuthInputs";
import { apiUrl } from "../api/url";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const Authentication = async () => {
    try {
      const response = await axios.post(apiUrl + "/api/auth/login", {
        email: email,
        password: password,
      });

      console.log("Authentication status:", response.data);
      Cookies.set("authKey", response.data, { expires: 30 });
      window.location.href = "/home/" + response.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error authentication");
    }
  };

  return (
    <div className="LoginContainer">
      <Authtitle
        Authsubtitle={"To continue your journey"}
        Authtitle={"Login"}
      />
      <div className="InputsContainer">
        <AuthInputs
          placeholder={"Email"}
          value={email}
          setvalue={setEmail}
          type={"text"}
        />
        <AuthInputs
          placeholder={"Password"}
          value={password}
          setvalue={setPassword}
          type={"password"}
        />
      </div>
      <div className="AuthButtons">
        <button onClick={() => Authentication()}>Login</button>
      </div>
      <div className="SupportContainer">
        <div id="SupportItems">
          <p>Don't have an account?</p>
          <a href="/authentication/register">
            <p>Register</p>
          </a>
        </div>
        <div id="SupportItems">
          <p>Forget your password?</p>

          <a href="/recover">
            <p>Recover</p>
          </a>
        </div>
      </div>
    </div>
  );
}
