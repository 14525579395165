export default function AuthInputs({ placeholder, value, setvalue, type }) {
  return (
    <input
      placeholder={placeholder}
      value={value}
      onChange={(e) => setvalue(e.target.value)}
      type={type}
    />
  );
}
