import React, { useEffect, useRef } from "react";

export default function ChatBox({ conversation }) {
  const chatEndRef = useRef(null);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversation]);

  return (
    <div id="ChatBox">
      {conversation.map((element, index) => (
        <div key={index} id={element.role === "user" ? "user" : "assistant"}>
          <p>{element.content}</p>
        </div>
      ))}
      <div ref={chatEndRef} />
    </div>
  );
}
