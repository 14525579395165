import axios from "axios";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";

import Logo from "../components/Logo/index";
import Chatbox from "../components/chatbox/index";
import Inputplace from "../components/inputplace/index";
import AIAnimation from "../components/AIAnimation/index";

// const apiUrl = "https://api.lilytech.org";
const apiUrl = "http://localhost:3001";

function Home() {
  const [mainBox, setMainBox] = useState("AIContainer_active");
  const [message, setMessage] = useState("");
  const [conversation, setConversation] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [base64image, setBase64image] = useState("");

  useEffect(() => {
    const authKey = Cookies.get("authKey");
    if (!authKey) {
      window.location.href = "/authentication/login"; // Redirect to login if no auth key
    }
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(apiUrl + "/api/chat", {
        message: message,
        base64image: base64image,
      });

      const data = response.data.message;
      setConversation((prevConversation) => [...prevConversation, data]);
      setMessage("");
      setSelectedFile(null);
      setBase64image("");
      setPreview(null);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setMainBox("AIContainer_inactive");
      setConversation((prevConversation) => [
        ...prevConversation,
        {
          role: "user",
          content: message,
        },
      ]);
      setMessage("");

      fetchData();
    }
  };

  const handleSend = () => {
    setMainBox("AIContainer_inactive");
    setConversation((prevConversation) => [
      ...prevConversation,
      {
        role: "user",
        content: message,
      },
    ]);
    setMessage("");

    fetchData();
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(apiUrl + "/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("File uploaded successfully:", response.data);
      alert("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file");
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result;
      setPreview(result);

      // Remove the data URL prefix (data:image/jpeg;base64,)
      const base64String = result.split(",")[1];
      setBase64image(base64String);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="App">
      <Logo />
      <AIAnimation mainBox={mainBox} />
      <Chatbox conversation={conversation} />
      <Inputplace
        handleKeyDown={handleKeyDown}
        message={message}
        setMessage={setMessage}
        handleSend={handleSend}
        handleFileChange={handleFileChange}
        handleSubmit={handleSubmit}
        preview={preview}
      />
    </div>
  );
}

export default Home;
