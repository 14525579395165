import React from "react";

export default function InputPlace({
  message,
  setMessage,
  handleKeyDown,
  handleSend,
  preview,
  handleFileChange,
  handleSubmit,
}) {
  const fileInputRef = React.createRef();

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div id="InputContainer">
      {preview && (
        <div id="previewImageContainer">
          <img
            src={preview}
            alt=""
            style={{ maxWidth: "300px", marginTop: "10px" }}
          />
        </div>
      )}
      <div id="miniContainer">
        <div id="ButtonContainer">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="inputfile"
            style={{ display: "none" }}
          />
          <img
            src={require("../../assets/add.png")}
            alt="Add file"
            onClick={handleImageClick}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div id="InputContent">
          <textarea
            id="InputPlace"
            spellCheck={false}
            placeholder="Speak to me"
            onKeyDown={handleKeyDown}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
        </div>
        <div id="ButtonContainer">
          <img
            src={require("../../assets/microphone.png")}
            alt="Microphone icon"
          />
        </div>
        <div id="ButtonContainer" onClick={() => handleSend()}>
          <img src={require("../../assets/send.png")} alt="Send message" />
        </div>
      </div>
    </div>
  );
}
