import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { useEffect } from "react";

import Login from "./screens/Login";
import Register from "./screens/Register";
import Home from "./screens/Home";

export default function App() {
  useEffect(() => {
    // Code to run once on component mount
    alert(
      "This is a test version!\nIf there is any problem, please contact: rayjohnson4126@gmail.com"
    );
  }, []);
  return (
    <Router>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/home/:chatid" element={<Home />} />
        <Route path="/authentication/login" element={<Login />} />
        <Route path="/authentication/register" element={<Register />} />
      </Routes>
    </Router>
  );
}
