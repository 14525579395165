import { useState } from "react";
import "../App.css";
import axios from "axios";
import Cookies from "js-cookie";

import Authtitle from "../components/AuthTitle";
import AuthInputs from "../components/AuthInputs";
import { apiUrl } from "../api/url";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");

  const Authentication = async () => {
    if (repassword === password) {
      try {
        const response = await axios.post(apiUrl + "/api/auth/register", {
          email: email,
          password: password,
        });

        console.log("Authentication status:", response.data);
        Cookies.set("authKey", response.data, { expires: 30 });
        window.location.href = "/home/" + response.data;
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Error authentication");
      }
    } else {
      alert("Check your password !");
    }
  };
  return (
    <div className="LoginContainer">
      <Authtitle
        Authtitle={"Register"}
        Authsubtitle={"To start your journey"}
      />
      <div className="InputsContainer">
        <AuthInputs
          placeholder={"Email"}
          value={email}
          setvalue={setEmail}
          type={"text"}
        />
        <AuthInputs
          placeholder={"Password"}
          value={password}
          setvalue={setPassword}
          type={"password"}
        />
        <AuthInputs
          placeholder={"Retype Password"}
          value={repassword}
          setvalue={setRepassword}
          type={"text"}
        />
      </div>
      <div className="AuthButtons">
        <button onClick={() => Authentication()}>Register</button>
      </div>
      <div className="SupportContainer">
        <div id="SupportItems">
          <p>Already have an account?</p>
          <a href="/authentication/login">
            <p>Login</p>
          </a>
        </div>
        <div id="SupportItems">
          <p>Forget your password?</p>
          <a href="/authentication/recover">
            <p>Recover</p>
          </a>
        </div>
      </div>
    </div>
  );
}
